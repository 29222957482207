import React, { useCallback, useEffect, useState } from "react";
import './Dailog.css'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import hidePwdImg from '../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../asset/new/icons/show_passwd.svg';
import {
  OtpVerification,
  googleLoginRequest,
  loginRequest,
} from "../../Redux/Slices/AuthSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Icongoogle from "../../asset/new/icons/icongoogle.svg";

const intialvalue = {
  email: "",
  password: "",
};

const SignIn = () => {
  const { redirectTo, otpModal } = useSelector((state) => state?.Auth);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [user, setUser] = useState(intialvalue);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalD, setShowModalD] = useState(false);

  // Google Login Hook
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      const decoded = jwtDecode(tokenResponse?.credential);
      responseGoogle(decoded);
    },
    onError: () => console.log('Login Failed'),
  });

  //---------- Form Validation -------------
  const validation = () => {
    let error = {};
    if (!user.email) {
      error.email = "Email is Required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        user.email
      )
    ) {
      error.email = "Enter a Valid Email";
    }
    if (!user.password) {
      error.password = "Password is Required";
    }
    return error;
  };

  //------------- Onchange validation ------------------
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Email is required" });
        setUser({ ...user, email: "" });
      } else {
        setError({ ...error, email: "" });
        setUser({ ...user, email: value });
      }
    }
    if (name === "password") {
      if (value.length === 0) {
        setError({ ...error, password: "Password is Required" });
        setUser({ ...user, password: "" });
      } else {
        setError({ ...error, password: "" });
        setUser({ ...user, password: value });
      }
    }
  };

  // Submit Button
  const SubmitInfo = async (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(ErrorList);
    let data = {
      email: user.email,
      password: user.password,
    };
    dispatch(loginRequest(data));
  };

  // Handle Otp
  const handleOtp = (e) => {
    let data = {
      email: user?.email,
      otp: otp,
      mode: "verify",
    };
    dispatch(OtpVerification(data));
    setShowModal(false);
    redirectUser();
    setOtp("");
  };

  // Google Authentication
  const responseGoogle = async (response) => {
    let data = await { email: response.email, id: response.sub };
    dispatch(googleLoginRequest(data));
  };

  // Redirect
  const redirectUser = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const isInLoginPage = window.location.pathname === "/signin";
    if (token && isInLoginPage) {
      navigate("/");
    }
    if (redirectTo === "/") {
      navigate("/");
    }
  }, [navigate, redirectTo]);

  useEffect(() => {
    redirectUser();
    if (otpModal) {
      setShowModal(true);
    }
    document.title = "SignIn | CAJobPortal";
  }, [redirectUser, otpModal]);

  const handleButtonClick = () => {
    localStorage.removeItem("name");
    setShowModalD(true);
  };

  const closeModal = () => {
    setShowModalD(false);
    setShowModal(false);
    setOtp("");
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOtp();
    }
  };

  return (
    <section className="wrapper mt-0">
      <div className="container pt-5">
        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
          <form className="rounded bg-white shadow py-4 px-4 mt-40">
            <h3 className="text-dark fw-bolder fs-4 mb-2">Sign In </h3>
            <div className="fw-normal text-muted mb-3">
              {" "}
              New Here? {" "}
              <span
                onClick={handleButtonClick}
                className="text-primary fw-weight text-decoration-none create-account"
              >
                Create an Account ?
              </span>

              {showModalD && (
                <div>
                  <input
                    type="checkbox"
                    name="dialog_state"
                    id="dialog_state"
                    className="dialog_state"
                    checked={showModalD}
                    onChange={() => {}}
                  />
                  <div id="dialog">
                    <label id="dlg-back" htmlFor="dialog_state" />
                    <div id="dlg-wrap">
                      <label
                        id="dlg-close"
                        htmlFor="dialog_state"
                        onClick={closeModal}
                      >
                        x
                      </label>
                      <h3 id="dlg-header">Create an Account ?</h3>
                      <div id="dlg-content">
                      </div>
                      <div id="dlg-prompt">
                      <Link to={"/signup"} className="btn px-sm-4 button1">
                        Join as Job seeker
                      </Link>
                      <Link to={"/signup-recruiter"} className="btn px-sm-4 button2">
                        Join as Recruiter
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                onChange={(e) => postUserData(e)}
                name="email"
                className="form-control"
                id="floatingInput1"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Email address</label>
              <span style={{ color: "red" }}> {error.email} </span>
            </div>
            <div className="form-floating pwd-container">
              <input
                type={isRevealPwd ? "text" : "password"}
                onChange={(e) => postUserData(e)}
                name="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
                <img
                title={isRevealPwd ? "Hide password" : "Show password"}
                alt="passwd_img"
                src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd(prevState => !prevState)}
               />
              <label htmlFor="floatingPassword">Password</label>
              <span style={{ color: "red" }}> {error.password} </span>
            </div>
            <div className="mt-4 text-center">
              <Link
                to={"/reset-account"}
                className="text-primary fw-weight text-decoration-none"
              >
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary submit_btn_signIn w-100 my-2"
              onClick={SubmitInfo}
            >
              Sign in
            </button>
            <span className="text-center text-muted text-uppercase mb-3">
              or
            </span>
            
            {/* Custom Google Sign-In Button */}
            <button 
              onClick={googleLogin}
              className="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center"
              style={{
                height: '45px',
                borderColor: '#ddd',
                color: '#444',
                fontSize: '15px',
                gap: '10px'
              }}
            >
              <img 
                src={ Icongoogle }
                alt="Google logo" 
                style={{ width: '20px' }}
              />
              Sign in with Google
            </button>
          </form>
        </div>
      </div>
      
      {/* Otp Modal */}
      <div
        className={`modal-backdrop fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>

      {/* Modal */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Verify Your Account
                <span className="otp-message">
                  Your code was sent to you via email
                </span>
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal} 
              />
            </div>
            <div className="modal-body">
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type="number"
                inputMode="numeric"
                className="form-control"
                id="floatingInput"
                placeholder="Enter OTP"
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleOtp}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;