import React, { useEffect, useState, useRef, useMemo } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { FetchUsers } from "../../Redux/Slices/User";
import {
  CreateSubscriptionByAdmin,
  GetOnePackage,
  GetPackage,
} from "../../Redux/Slices/Package";

const AddAccess = () => {
  const dispatch = useDispatch();
  const { usersData } = useSelector((state) => state?.User);
  const dropdownRef = useRef(null);
  const packageDropdownRef = useRef(null);

  // State management
  const [formData, setFormData] = useState({
    package: "",
    remarks: ""
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [packageDetails, setPackageDetails] = useState([]);
  const [purchase, setPurchase] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPackageDropdown, setShowPackageDropdown] = useState(false);
  const [packageSearchTerm, setPackageSearchTerm] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);

  // Memoized filtered users
  const jobRecruiterUsers = useMemo(() =>
    usersData?.docs?.filter(user => user?.role_id?.name === "Employer") || []
  , [usersData]);

  const filteredUsers = useMemo(() => {
    if (!searchTerm) return jobRecruiterUsers;
    return jobRecruiterUsers.filter(user =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, jobRecruiterUsers]);

  // Filtered active packages with search
  const filteredPackages = useMemo(() => {
    const activePackages = packageDetails.filter(item => item?.is_active);
    if (!packageSearchTerm) return activePackages;
    return activePackages.filter(pkg => 
      pkg.name.toLowerCase().includes(packageSearchTerm.toLowerCase())
    );
  }, [packageDetails, packageSearchTerm]);

  // Initial data fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = { role: "Employer", alllist: "all" };
        await dispatch(FetchUsers(userData));

        const pkgData = await GetPackage();
        setPackageDetails(pkgData?.docs || []);

        document.title = "Add Access | Admin";
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  // Package details fetch
  useEffect(() => {
    const fetchPackageDetails = async () => {
      if (!formData.package) return;
      try {
        const data = await GetOnePackage(formData.package);
        setPurchase(data);
      } catch (error) {
        console.error("Error fetching package details:", error);
      }
    };

    fetchPackageDetails();
  }, [formData.package]);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (packageDropdownRef.current && !packageDropdownRef.current.contains(event.target)) {
        setShowPackageDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleUserSelect = (user) => {
    setSelectedUser({
      value: user.email,
      label: user.email,
      id: user._id
    });
    setSearchTerm(user.email);
    setShowDropdown(false);
  };

  const handlePackageSelect = (pkg) => {
    setFormData(prev => ({ ...prev, package: pkg._id }));
    setSelectedPackage(pkg);
    setPackageSearchTerm(pkg.name);
    setShowPackageDropdown(false);
  };

  const clearPackageSelection = () => {
    setFormData(prev => ({ ...prev, package: "" }));
    setSelectedPackage(null);
    setPackageSearchTerm("");
    setPurchase(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      alert("Please select an employer");
      return;
    }

    if (!formData.package) {
      alert("Please select a package");
      return;
    }

    try {
      const subscriptionData = {
        payment_id: formData.remarks,
        package: formData.package,
        employer: selectedUser.id,
        avail_jobposting: purchase?.no_job_posting,
        avail_cvsearch: purchase?.no_cv_search,
        validity_day: purchase?.validity_day,
      };

      await CreateSubscriptionByAdmin(subscriptionData);
      // Reset form after successful submission
      setFormData({ package: "", remarks: "" });
      setSelectedUser(null);
      setSearchTerm("");
      setPackageSearchTerm("");
      setSelectedPackage(null);
      setPurchase(null);
      alert("Subscription created successfully!");
    } catch (error) {
      console.error("Error creating subscription:", error);
      alert("Failed to create subscription");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          <h2 className="headline">Add Access</h2>
          <div className="main-content-child">
            <div className="card mt-3">
              <div className="card-header">Add Access</div>
              <form onSubmit={handleSubmit} className="row m-0">
                {/* Employer Search */}
                <div className="add-acess-child col-md-3">
                  <label>Employer</label>
                  <div className="custom-select-wrapper" ref={dropdownRef}>
                    <div className="custom-select-container">
                      <input
                        type="text"
                        className="form-control custom-select-input"
                        placeholder="Search employer..."
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          setShowDropdown(true);
                        }}
                        onFocus={() => setShowDropdown(true)}
                        required
                      />
                     
                      <div className="position-absolute end-0 top-50 translate-middle-y pe-3">
                        <svg
                          onClick={() => setShowDropdown(!showDropdown)}
                          aria-hidden="true"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L6 6L11 1"
                            stroke="#495057"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {showDropdown && (
                      <div className="custom-select-dropdown">
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((user) => (
                            <div
                              key={user._id}
                              className="custom-select-option"
                              onClick={() => handleUserSelect(user)}
                            >
                              {user.email}
                            </div>
                          ))
                        ) : (
                          <div className="custom-select-option no-results">
                            No employers found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* Package Selection - Improved with selected package display */}
                <div className="add-acess-child col-md-3">
                  <label>Package</label>
                  <div className="custom-select-wrapper" ref={packageDropdownRef}>
                    <div className="custom-select-container">
                      {selectedPackage ? (
                        <div className="selected-package-container">
                          <span className="selected-package-name">
                            {selectedPackage.name}
                          </span>
                          <button
                            type="button"
                            className="btn-close-package"
                            onClick={clearPackageSelection}
                            aria-label="Clear selection"
                          >
                            ×
                          </button>
                        </div>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="form-control custom-select-input"
                            placeholder="Search package..."
                            value={packageSearchTerm}
                            onChange={(e) => {
                              setPackageSearchTerm(e.target.value);
                              setShowPackageDropdown(true);
                            }}
                            onFocus={() => setShowPackageDropdown(true)}
                            required
                          />
                          <div className="position-absolute end-0 top-50 translate-middle-y pe-3">
                            <svg
                              onClick={() => setShowPackageDropdown(!showPackageDropdown)}
                              aria-hidden="true"
                              width="12"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L6 6L11 1"
                                stroke="#495057"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </>
                      )}
                    </div>
                    {showPackageDropdown && (
                      <div className="custom-select-dropdown">
                        {filteredPackages.length > 0 ? (
                          filteredPackages.map((pkg) => (
                            <div
                              key={pkg._id}
                              className="custom-select-option"
                              onClick={() => handlePackageSelect(pkg)}
                            >
                              {pkg.name}
                            </div>
                          ))
                        ) : (
                          <div className="custom-select-option no-results">
                            No packages found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* Rest of the form remains the same */}
                <span className="card-header-package">Package Details</span>

                <div className="add-acess-child col-md-3">
                  <label>Package Type</label>
                  <input
                    type="text"
                    className="form-control"
                    value={purchase?.name || ""}
                    disabled
                  />
                </div>

                <div className="add-acess-child col-md-3">
                  <label>No of Job Post</label>
                  <input
                    type="text"
                    className="form-control"
                    value={purchase?.no_job_posting || ""}
                    disabled
                  />
                </div>

                <div className="add-acess-child col-md-3">
                  <label>No of CV Search</label>
                  <input
                    type="text"
                    className="form-control"
                    value={purchase?.no_cv_search || ""}
                    disabled
                  />
                </div>

                <div className="add-acess-child col-md-3">
                  <label>Validity Days</label>
                  <input
                    type="text"
                    className="form-control"
                    value={purchase?.validity_day || ""}
                    disabled
                  />
                </div>

                <div className="add-acess-child col-md-3">
                  <label>Remarks</label>
                  <input
                    type="text"
                    name="remarks"
                    onChange={handleInputChange}
                    value={formData.remarks}
                    className="form-control"
                    placeholder="Enter remarks (optional)"
                  />
                </div>

                <div className="col-md-12 text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-success add-acess-submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .custom-select-wrapper {
          position: relative;
          width: 100%;
        }
        
        .custom-select-container {
          position: relative;
          display: flex;
          align-items: center;
        }
        
        .custom-select-input {
          width: 100%;
          padding-right: 30px;
        }
        
        .custom-select-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 1000;
          max-height: 200px;
          overflow-y: auto;
          background: white;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          margin-top: 2px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        
        .custom-select-option {
          padding: 8px 12px;
          cursor: pointer;
          transition: background-color 0.2s;
        }
        
        .custom-select-option:hover {
          background-color: #0000FF;
          color: #fff;
        }
        
        .no-results {
          color: #6c757d;
          font-style: italic;
          cursor: default;
        }
        
        .add-acess-child {
          padding: 0 15px;
          margin-bottom: 1rem;
        }
        
        .card-header-package {
          display: block;
          padding: 0 15px;
          margin: 15px 0;
          font-weight: bold;
          color: #f8f9fa;
        }
        
        .selected-package-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0.375rem 0.75rem;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          background-color: #f8f9fa;
        }
        
        .selected-package-name {
          flex-grow: 1;
        }
        
        .btn-close-package {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0 0.5rem;
          font-size: 1.25rem;
          color: #6c757d;
        }
        
        .btn-close-package:hover {
          color: #495057;
        }
      `}</style>
    </div>
  );
};

export default AddAccess;