
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import hidePwdImg from '../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../asset/new/icons/show_passwd.svg';
import {
  googleRegisterRequest,
  registerUser,
} from "../../Redux/Slices/AuthSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Icongoogle from "../../asset/new/icons/icongoogle.svg";

const intialvalue = {
  name: "",
  email: "",
  mobile: "",
  password: "",
  role_id: "Jobseeker",
  experience_level: "",
};
const SignUp = () => {
  const [user, setUser] = useState(intialvalue);
  const [error, setError] = useState({});
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectReg } = useSelector((state) => state?.Auth);
  const [showModal, setShowModal] = useState(false);
  const [googleAuthDetails, setgoogleAuthDetails] = useState({});


  // Google login Hook
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      const decoded = jwtDecode(tokenResponse?.credential);
      responseGoogle(decoded);
    },
    onError: () => console.log('Login Failed'),
  });


  // Form Validation
  const validation = () => {
    let error = {};
    if (!user.name) {
      error.name = "@Name is Required";
    }
    if (!user.email) {
      error.email = "@Email is Required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        user.email
      )
    ) {
      error.email = "@Enter a Valid Email";
    }
    if (!user.password) {
      error.password = "@Password is Required";
    }
    if (!user.mobile) {
      error.mobile = "@Mobile is Required";
    }
    return error;
  };

  // ------------- OnChange Validation --------------
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
    if (name === "name") {
      if (value.length === 0) {
        setError({ ...error, name: "Name is Required" });
        setUser({ ...user, name: "" });
      } else {
        setError({ ...error, name: "" });
        setUser({ ...user, name: value });
      }
    }
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Email is required" });
        setUser({ ...user, email: "" });
      } else {
        setError({ ...error, email: "" });
        setUser({ ...user, email: value });
      }
    }
    if (name === "mobile") {
      if (value.length === 0) {
        setError({ ...error, mobile: "Mobile is Required" });
        setUser({ ...user, mobile: "" });
      } else {
        setError({ ...error, mobile: "" });
        setUser({ ...user, mobile: value });
      }
    }
    if (name === "password") {
      if (value.length === 0) {
        setError({ ...error, password: "Password is Required" });
        setUser({ ...user, password: "" });
      } else {
        setError({ ...error, password: "" });
        setUser({ ...user, password: value });
      }
    }
  };

  // Submit Button
  const SubmitInfo = async (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(ErrorList);

    let data = {
      name: user.name,
      email: user.email,
      password: user.password,
      mobile: user.mobile,
      role_id: "Jobseeker",
      experience_level: user.experience_level,
    };
    dispatch(registerUser(data));
  };

  // Catch previous response
  const postUserData2 = (e) => {
    name = e.target.name;
    value = e.target.value;
    setgoogleAuthDetails((prevData) => ({ ...prevData, [name]: value }));
  };

  // Google Authentication
  const responseGoogle = async (response) => {
    if (response) {
      // setShowModal(true);
      let data = {
        name: response?.name,
        email: response.email,
        google_id: response?.sub,
        role_id: "Jobseeker",
      };
      dispatch(googleRegisterRequest(data));
    }
  };

  useEffect(() => {
    // Redirect
    const redirectUser = () => {
      let name = localStorage.getItem("name");
      let isInLoginPage = window.location.pathname.toLowerCase() === "/signup";
      if (name !== null && name !== undefined && name !== "") {
        isInLoginPage && navigate("/");
      }
    };
    document.title = "SignUp | CAJobPortal";
    redirectUser();
  }, [redirectReg]);

  return (
    <section className="wrapper">
      <div className="container pt-5">
        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
          <form className="rounded bg-white shadow py-4 px-4">
            <h3 className="text-dark fw-bolder fs-4 mb-2">Create an Account</h3>
            <div className="fw-normal text-muted ">
              Already have an account?
              <Link
                to={"/signin"}
                className="text-primary fw-weight text-decoration-none"
              >
                {" "}
                Sign in Here{" "}
              </Link>
            </div>
            <span>Or</span>
            <div className="fw-normal text-muted mb-3">
              Register as
              <Link
                to={"/signup-recruiter"}
                className="text-primary fw-weight text-decoration-none"
              >
                {" "}
                Recruiter{" "}
              </Link>
            </div>
            <div className="form-floating mb-3">
              <input
                name="name"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput1"
                placeholder="Your Name"
              />
              <label>Name</label>
              <span style={{ color: "red" }}> {error.name} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="email"
                onChange={(e) => postUserData(e)}
                type="email"
                className="form-control"
                id="floatingInput2"
                placeholder="name@example.com"
              />
              <label>Email address</label>
              <span style={{ color: "red" }}> {error.email} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="mobile"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput3"
                placeholder="Enter Mobile Number"
              />
              <label>Mobile Number*</label>

              <span style={{ color: "red" }}> {error.mobile} </span>
            </div>
            <div className="form-floating pwd-container mb-3">
              <input
                name="password"
                onChange={(e) => postUserData(e)}
                type={isRevealPwd ? "text" : "password"}
                className="form-control"
                id="floatingPassword1"
                placeholder="Password"
              />
              <img
                title={isRevealPwd ? "Hide password" : "Show password"}
                alt="passwd_img"
                src={isRevealPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealPwd(prevState => !prevState)}
              />
              <label>Password</label>
              <span style={{ color: "red" }}> {error.password} </span>
            </div>
            <select
              className="form-select mb-3"
              aria-label="Default select example"
              onChange={(e) => postUserData(e)}
              name="experience_level"
            >
              <option>Experience Level</option>
              <option value={"Fresher"}>Fresher</option>
              <option value={"Experienced"}>Experienced</option>
            </select>

            <button
              onClick={SubmitInfo}
              type="submit"
              className="btn btn-primary submit_btn_signIn w-100 my-2"
            >
              Sign Up
            </button>
            <span className="text-center text-muted text-uppercase mb-3">
              or
            </span>

            {/* Custom Google Sign-In Button */}
            <button
              onClick={googleLogin}
              className="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center"
              style={{
                height: '45px',
                borderColor: '#ddd',
                color: '#444',
                fontSize: '15px',
                gap: '10px'
              }}
            >
              <img
                src={Icongoogle}
                alt="Google logo"
                style={{ width: '20px' }}
              />
              Sign UP with Google
            </button>
          </form>
        </div>
        {/* Required Modal */}
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden={!showModal}
          style={{ display: showModal ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Required Details
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="modal-body">
                <label>Mobile Number*</label>
                <input
                  name="mobile"
                  onChange={(e) => postUserData2(e)}
                  type="text"
                  className="form-control my-3"
                  id="floatingInput3"
                  placeholder="Enter Mobile Number"
                />
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  onChange={(e) => postUserData2(e)}
                  name="experience_level"
                >
                  <option>Experience Level</option>
                  <option value={"Fresher"}>Fresher</option>
                  <option value={"Experienced"}>Experienced</option>
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                // onClick={googlauthHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
