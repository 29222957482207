import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

const initialState = {
  loading: false,
  user: {}, //For User Object
  userToken: null,
  redirectTo: null,
  redirectReg: null,
  userAuth: null,
  otpModal: null,
};
export const registerUser = createAsyncThunk("/register", async (user) => {
  try {
    const res = await axiosInstance.post("register", user);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
});

export const loginRequest = createAsyncThunk("/login", async (user) => {
  try {
    const res = await axiosInstance.post("login", user);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
});
export const googleLoginRequest = createAsyncThunk(
  "/googlelogin",
  async (user) => {
    try {
      const res = await axiosInstance.post("googlelogin", user);
      return res?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error.message;
    }
  }
);
export const googleRegisterRequest = createAsyncThunk(
  "/googleregister",
  async (user) => {
    try {
      const res = await axiosInstance.post("googleregister", user);
      return res?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return error.message;
    }
  }
);

//Otp Request
export const OtpRequest = async (data) => {
  try {
    let res = await axiosInstance.post("otp", data);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

//Otp Verification
export const OtpVerification = createAsyncThunk("/otp", async (data) => {
  try {
    let res = await axiosInstance.post("otp", data);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data);
    return error.message;
  }
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Logout
    logout: (state, { payload }) => {
      localStorage.clear();
      toast.success("Logout successfully");
      state.LogoutToggle = false;
      state.redirectTo = null;
      state.otpModal = false;
      state.userToken = null;
      window.location.reload();
    },

    redirectToo: (state, { payload }) => {
      state.redirectTo = payload;
    },
  },
  extraReducers: {
    //Register

    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      if (payload?.success) {
        localStorage.setItem("name", payload?.user?.name);
        state.redirectReg = "/signin";
        toast(`Hi! ${payload?.user?.name} Register successfully`);
      }
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Login
    [loginRequest.pending]: (state, action) => {
      state.loading = true;
      state.otpModal = false;
    },
    [loginRequest.fulfilled]: (state, { payload }) => {
      if (payload?.user) {
        // state.userToken = payload?.token;
        OtpRequest({ email: payload?.user?.email, mode: "request" });
        state.otpModal = true;
        state.userAuth = true;
      }
    },
    [loginRequest.rejected]: (state) => {
      state.loading = false;
      state.otpModal = false;
    },

    // Google Register
    [googleRegisterRequest.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [googleRegisterRequest.fulfilled]: (state, { payload }) => {
      if (payload?.token) {
        // console.log(payload);    
        localStorage.setItem("name", payload?.user?.name);
        localStorage.setItem("token", JSON.stringify(payload?.token));
        localStorage.setItem("role", payload?.user?.role_id?.name);
        localStorage.setItem("id", payload?.user?._id);
        window.location.reload();
        state.redirectReg = "/";
        toast(`Hi! ${payload?.user?.name} Register successfully`);
      }
    },
    [googleRegisterRequest.rejected]: (state, { payload }) => {
      state.loading = false;
      // state.error = payload;
    },

    // Google Login
    [googleLoginRequest.pending]: (state, action) => {
      state.loading = true;
      state.otpModal = false;
    },
    [googleLoginRequest.fulfilled]: (state, { payload }) => {
      if (payload?.token) {
        toast.success("Login Successfully");
        localStorage.removeItem("email", payload?.is_googleLogin?.email);
        localStorage.setItem("token", JSON.stringify(payload?.token));
        // localStorage.setItem("token", state.userToken || payload?.token);
        localStorage.setItem("name", payload?.is_googleLogin?.name);
        localStorage.setItem("role", payload?.is_googleLogin?.role_id?.name);
        localStorage.setItem("id", payload?.is_googleLogin?.user_id);
        window.location.reload();
        state.redirectTo = "/";
      }
    },
    [googleLoginRequest.rejected]: (state, action) => {
      state.otpModal = false;
    },

    // Otp verification

    [OtpVerification.pending]: (state, action) => {
      state.loading = true;
    },
    [OtpVerification.fulfilled]: (state, { payload }) => {
      if (payload?.user) {
        toast.success("Login Successfully");
        localStorage.removeItem("email", payload?.user?.email);
        localStorage.setItem("token", JSON.stringify(payload?.token));
        localStorage.setItem("name", payload?.user?.name);
        localStorage.setItem("role", payload?.user?.role_id?.name);
        localStorage.setItem("id", payload?.user?.user_id);
        window.location.reload();
        state.redirectTo = "/";
      }
    },
    [OtpVerification.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { checkToken, redirectReg, logout } = AuthSlice.actions;
