import React from "react";
import Bajaj from "../../../asset/new/images/slider_img/Bajaj.png";
import accenture from "../../../asset/new/images/slider_img/accenture.png";
import Britannia from "../../../asset/new/images/slider_img/Britannia.png";
import caterpillar from "../../../asset/new/images/slider_img/caterpillar.png";
import Ceat from "../../../asset/new/images/slider_img/Ceat.png";
import cloudtail from "../../../asset/new/images/slider_img/cloudtail.png";
import fullerton_india from "../../../asset/new/images/slider_img/fullerton_india.png";
import gold_man from "../../../asset/new/images/slider_img/gold_man.png";
import hdfc from "../../../asset/new/images/slider_img/hdfc.png";
import HUL from "../../../asset/new/images/slider_img/HUL.png";
import imersys from "../../../asset/new/images/slider_img/imersys.png";
import Manyavar from "../../../asset/new/images/slider_img/Manyavar.png";
import pernod from "../../../asset/new/images/slider_img/pernod.png";
import philips from "../../../asset/new/images/slider_img/philips.png";
import STC from "../../../asset/new/images/slider_img/STC.png";
import TATAcapital from "../../../asset/new/images/slider_img/tata capital.png";
import wildcraft from "../../../asset/new/images/slider_img/wildcraft.png";

const HomeCarousel = () => {
  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <p className="text-center mb-3" style={{ fontSize: "16px" }}>
          <span>2500+</span>
          BUSINESSES TRUST US
        </p>
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img 
                src={Bajaj}
                className="slider_img"
                alt="bajaj"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img 
                src={accenture}
                className="slider_img"
                alt="accenture"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={Britannia}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={caterpillar}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={Ceat}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={cloudtail}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={fullerton_india}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={gold_man}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={hdfc}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={HUL}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={imersys}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={Manyavar}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={pernod}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={philips}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={STC}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={TATAcapital}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
            <div className="slide">
              <img
                src={wildcraft}
                className="slider_img"
                alt="logo"
                loading="lazy"
                width="100"
                height="100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
