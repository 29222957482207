import React from "react";
import "./Navbar_and_Footer.css";
import FooterLogo from "../../asset/new/images/jobseeker_footer_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFacebookF,
  faWhatsapp,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer_css text-center">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="align-self-center col-12 col-lg-2 d-none d-lg-flex">
              <img
                src={FooterLogo}
                className="logo_img_footer"
                alt="our_logo"
              />
            </div>
            <div className="align-self-center col-lg-4 d-none d-lg-block">
              <p className="mb-0 copyright" style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>Copyright reserved 2022 CAJobPortal</p>
            </div>
            <div className="align-self-center col-12 col-lg-6 mb-2 mb-lg-0">
              <div className="container-fluid p-0">
                <div className="row p-0">
                  <div className="align-self-center col-12 col-lg-9 mb-2 mb-lg-0 text-start mt-2">
                    <ul className="footer_div2 text-center">
                      <li><Link to={"/about"} style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>About</Link></li>
                      <li><Link to={"/contact"} style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>Contact Us</Link></li>
                      <li><Link to={"/faq"} style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>Privacy</Link></li>
                      <li><Link to={"/recruiter"} style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>Subscription</Link></li>
                    </ul>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-end mt-2">
                    <Link to="https://www.facebook.com/cajobportal" target="_blank">
                      <FontAwesomeIcon className="font-awesome footer-icons" icon={faFacebookF} />
                    </Link>{" "}
                    <Link to="https://www.linkedin.com/in/cajobportal/?originalSubdomain=in" target="_blank">
                      <FontAwesomeIcon className="font-awesome footer-icons" icon={faLinkedinIn} />
                    </Link>{" "}
                    <Link to="https://twitter.com/cajobportal" target="_blank">
                      <FontAwesomeIcon className="font-awesome footer-icons" icon={faXTwitter} />
                    </Link>{" "}
                    <Link to="https://chat.whatsapp.com/E9MIMEtvZCG9HcW6gfl4Vi" target="_blank">
                      <FontAwesomeIcon className="font-awesome footer-icons" icon={faWhatsapp} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="align-self-center col-12 text-center d-block d-lg-none">
              <div className="row">
                <div className="col-12">
                  <img
                    src={FooterLogo}
                    className="m-auto"
                    alt="our_logo"
                    width="150"
                    height="75"
                  />
                </div>
                <div className="col-12">
                  <span className="d-inline-block copyright" style={{fontSize: "clamp(1.2rem, 2.5vw, 2.5rem)"}}>
                    Copyright reserved 2022 CAJobPortal
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

