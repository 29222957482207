import React, { useEffect, useState } from "react";
import "./Jobseeker.css";
import JobSeekerBanner from "../../../asset/new/images/jobseeker_banner.svg";
import BlueStrip from "./../Home/BlueStrip";
import HomeCarousel from "../Home/HomeCarousel";
import JobCategory from "./JobCategory";
import Cities from "../../../Helper/CountryStateCity.json";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FetchSkill } from "../../../Redux/Slices/Skill";
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const JobSeeker = () => {
  const { skillsData } = useSelector((state) => state?.Skill);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [data, setData] = useState({ cities: [], skills: [] });
  const [selectedCity, setSelectedCity] = useState([]);

  const FilteredCities = Cities?.find(country => country.name === "India")
  ?.states?.flatMap(state => state?.cities) || [];


   // Custom styles for the dropdown
   const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      minHeight: "48px",
      boxShadow: "none", // Removes the default focus outline
      "&:hover": {
        border: "none",
      },
    }),
  };
  const optionsCity = FilteredCities?.map((item) => ({
    value: item.name,
    label: item.name,
  })).sort((a, b) => a?.label?.localeCompare(b.label));

  const handleSelectChangeCity = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions?.map((option) => option.value)
      : [];
    setSelectedCity(values);
    setData((prevData) => ({
      ...prevData,
      cities: values,
    }));
  };

  const handleClick = () => {
    const dataToSend = data;
    navigate("/featured-job", { state: { data: dataToSend } });
  };

  useEffect(() => {
    let data = {
      page: 1,
      search: "",
    };
    dispatch(FetchSkill(data));
  }, []);

  return (
    <>
      <Helmet>
        <title>JobSeeker</title>
        <meta
          name="description"
          content="Connect with top recruiters and find your ideal job today"
        />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center banner-col">
            <img
              className="banner1"
              style={{ width: "98vw"}}
              src={JobSeekerBanner}
              alt="jobseeker_banner"
            />
            <form>
              <div className="container-fluid searchp two_input">
                <div className="row">
                  <div className="col text-start">
                    {/* ------- City -------- */}
                    {/* <p className="city_style">City</p> */}
                    <Select
                      className="multiple-select homedrop"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={optionsCity}
                      onChange={handleSelectChangeCity}
                      styles={customStyles}
                    />
                    {/* <select
                      onChange={(e) =>
                        setData({ ...data, cities: [e.target.value] })
                      }
                      className="input2"
                      id="cityList"
                    >
                      <option value="">Select City</option>
                      {Cities?.sort((a, b) => a.name.localeCompare(b.name)).map(
                        (city, index) => (
                          <option key={index} value={city?.name}>
                            {city?.name}
                          </option>
                        )
                      )}
                    </select> */}
                  </div>
                  {/* ------- Job Type -------- */}

                  {/* <div className="col-5 text-start">
                    <p className="job_type_style">Job Type</p>
                    <select
                      onChange={(e) =>
                        setData({ ...data, skills: [e.target.value] })
                      }
                      id="jobtypeList"
                      className="input2"
                    >
                      <option value="">Select Type</option>


                     {skillsData?.itDocs?.map((value, index) => (
                        <option
                          key={`it-${index}`}
                          value={value?.name}
                          label={value?.name}
                        />
                      ))}


                      {skillsData?.functionalDocs?.map((value, index) => (
                        <option
                          key={`functional-${index}`}
                          value={value?.name}
                          label={value?.name}
                        />
                      ))}
                    </select>
                  </div> */}

                  {/*  --------- Search Button -------  */}
                  <div className="col-2 p-0">
                    <div
                      className="rounded-circle"
                      style={{
                        width: 50,
                        height: 50,
                        marginLeft: "auto",
                        backgroundColor: "#5FC2EC",
                        alignSelf: "center",
                      }}
                    >
                      <button
                        type="button"
                        style={{ background: "transparent", border: "none" }}
                        onClick={handleClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={25}
                          fill="#FFFFFF"
                          className="bi bi-search"
                          style={{ marginTop: 12, marginLeft: 3 }}
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/*-------- Blue Strip ------- */}
      <BlueStrip />

      {/* <!-- Carousel Section --> */}

      <HomeCarousel />

      {/* <!-- JobCategory Section --> */}
      <JobCategory />
    </>
  );
};

export default JobSeeker;
