import { Navigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Make sure to use the correct import for jwt-decode

export default function RequiredAuth({ children, allowedRoles }) {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/" />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken?.role?.name;

    if (!userRole || !allowedRoles.includes(userRole)) {
      return <Navigate to="/" />;
    }

    return children;
  } catch (error) {
    localStorage.clear();
    return <Navigate to="/" />;
  }
}

