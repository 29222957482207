import React from "react";
import FeaturedJob from "../../../asset/new/images/Frame_325.png";
import PSUJob from "../../../asset/new/images/Frame_326.png";
import ThirdParty from "../../../asset/new/images/Frame_327.png";
import Newsletter from "../Newsletter";
import { Link } from "react-router-dom";

const JobCategory = () => {
  return (
    <>
      <div className="container" style={{ marginTop: 50, maxWidth: "90vw" }}>
        <div className="row">
          <h4 className="mb-4">Job Category</h4>
          {[{
            to: '/featured-job',
            imgSrc: FeaturedJob,
            title: 'Featured Jobs',
            desc: 'Jobs managed by CAJobPortal on behalf of clients'
          }, {
            to: '/career-sites',
            imgSrc: PSUJob,
            title: 'PSU Jobs / Career sites',
            desc: 'Jobs hosted on the career sites of companies'
          }, {
            to: '/third-party-listing',
            imgSrc: ThirdParty,
            title: 'Third party listings',
            desc: 'Jobs posted directly by companies / search firms'
          }].map((job, index) => (
            <div className="col-12 col-md-4 mt-3 mt-md-0" key={index}>
              <Link to={job.to} style={{ color: "black", textDecoration: "none" }}>
                <div className="card" style={{
                  maxWidth: "100%",
                  margin: "auto",
                  borderRadius: 10,
                  backgroundColor: "#BBE6F9",
                  height: "100%",
                  border: "none"
                }}>
                  <div className="card-body cord">
                    <h5 className="text-center">
                      <img src={job.imgSrc} style={{
                        width: 45,
                        height: "auto",
                        objectFit: "cover"
                      }} alt="category" />
                    </h5>
                    <h5 className="card-title text-center job_category_title">
                      {job.title}
                    </h5>
                    <p className="card-text text-center job_category_desc" style={{ fontSize: 12 }}>
                      {job.desc}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default JobCategory;

