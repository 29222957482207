import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { BsFillPersonFill, BsListStars } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { AiOutlinePlus, AiOutlineUnorderedList, AiTwotoneFolderAdd } from "react-icons/ai";
import { MdPersonSearch } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
const AdminSideBar = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <div className="container m-0 ">
        <div className="admin-sidenav  mt-2">
          <NavLink
            to={"/admin/dashboard"}
            className={
              pathname === "/admin/dashboard"
                ? "admin-navlink-active"
                : "admin-navlink"
            }
          >
            <FaHome className="me-1 fs-4 " /> Dashboard
          </NavLink>
          <NavLink
            to={"/admin/profile"}
            className={
              pathname === "/admin/profile"
                ? "admin-navlink-active"
                : "admin-navlink"
            }
          >
            <BsFillPersonFill className="me-1 fs-4" /> My Profile
          </NavLink>
          <NavLink
            to={"/admin/jobSeekerList"}
            className={
              pathname === "/admin/jobSeekerList"
                ? "admin-navlink-active"
                : "admin-navlink"
            }
          >
            <AiOutlineUnorderedList className="me-1 fs-4" /> Job Seekers List
          </NavLink>
          <NavLink
            to={"/admin/employerList"}
            className={
              pathname === "/admin/employerList"
                ? "admin-navlink-active"
                : "admin-navlink"
            }
          >
            <AiOutlineUnorderedList className="me-1 fs-4" /> Employers List
          </NavLink>

          <NavLink
            to={"/admin/postjob"}
            className={
              pathname === "/admin/postjob" ? "admin-navlink-active" : "admin-navlink"
            }
          >
            <AiOutlineUnorderedList className="me-1 fs-4" /> Post Job
          </NavLink>

          <NavLink
            to={"/admin/approveJobs"}
            className={
              pathname === "/admin/approveJobs" ? "admin-navlink-active" : "admin-navlink"
            }
          >
            <AiOutlineUnorderedList className="me-1 fs-4" /> Approve Jobs
          </NavLink>

          <NavLink
            to={"/admin/postedjobs"}
            className={
              pathname === "/admin/postedjobs" ? "admin-navlink-active" : "admin-navlink"
            }
          >
            <AiOutlineUnorderedList className=" fs-4" /> Posted Jobs
          </NavLink>

          

          <NavLink to={"/admin/folder"}  className={
              pathname === "/admin/folder" ? "admin-navlink-active" : "admin-navlink"
            }>
            <AiTwotoneFolderAdd className="me-1 fs-4" /> Manage Folders
          </NavLink>

          <NavLink to={"/admin/candidateSearch"}
            className={
              pathname === "/admin/candidateSearch" ? "admin-navlink-active" : "admin-navlink"
            }>
            <MdPersonSearch className="me-1 fs-4" /> Candidate Search
          </NavLink>
          <NavLink to={"/admin/addaccess"} className={
              pathname === "/admin/addaccess" ? "admin-navlink-active" : "admin-navlink"
            }>
            <IoIosAddCircle className="me-1 fs-4" /> Add Access
          </NavLink>
          <NavLink to={"/admin/packageSalesHistory"} className={
              pathname === "/admin/packageSalesHistory" ? "admin-navlink-active" : "admin-navlink"
            }>
            <GiReceiveMoney className="me-1 fs-4" /> Package Sales History
          </NavLink>
          <div className="accordion-item" >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button admin-navlink"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
               
              >
              <BsListStars className="me-1 fs-4 "/>Master
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="d-flex flex-column ps-4">
                <NavLink to={"/admin/skill"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Skill
                </NavLink>
                <NavLink to={"/admin/industry"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Industry
                </NavLink>
                <NavLink to={"/admin/degrees"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Degree
                </NavLink>
                <NavLink to={"/admin/qualification"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Qualification
                </NavLink>
                <NavLink to={"/admin/jobtype"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Job Type
                </NavLink>
                <NavLink to={"/admin/sliders"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Sliders
                </NavLink>
                <NavLink to={"/admin/subscription"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Subscription
                </NavLink>
                <NavLink to={"/admin/contactus"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Contact Us
                </NavLink>
                <NavLink to={"/admin/aboutus"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> About Us
                </NavLink>
                <NavLink to={"/admin/faq"} className="admin-navlink fs-6">
                <AiOutlinePlus className="me-1 fs-6"/> Privacy Policy
                </NavLink>
              </div>
            </div>
          </div>
          </div>
        </div>
    </div>
  );
};

export default AdminSideBar;
