export const handleDownload = (url) => {
  // Url Path
  let resumeUrl = process.env.REACT_APP_AZURE_STORAGE_URL;
  url = resumeUrl + url;
  window.open(url, "_blank");
};

export const getProgressBarColor = (percentage) => {
  if (percentage < 30) {
    return "bg-danger";
  } else if (percentage >= 30 && percentage < 50) {
    return "bg-warning";
  } else if (percentage >= 50 && percentage < 80) {
    return "bg-info";
  } else {
    return "bg-success";
  }
};
export function formatDate(inputDateString) {
  const inputDate = new Date(inputDateString);

  const year = inputDate.getFullYear();
  const month = inputDate.getMonth() + 1; // Months are zero-based, so we add 1
  const day = inputDate.getDate();

  const formattedDateString = `${month < 10 ? "0" : ""}${month}/${
    day < 10 ? "0" : ""
  }${day}/${year}`;

  return formattedDateString;
}

