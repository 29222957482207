import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import hidePwdImg from '../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../asset/new/icons/show_passwd.svg';
import {
  googleRegisterRequest,
  registerUser,
} from "../../Redux/Slices/AuthSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Icongoogle from "../../asset/new/icons/icongoogle.svg";
const intialvalue = {
  name: "",
  email: "",
  mobile: "",
  password: "",
  role_id: "Employer",
  designation: "",
  companyName: "",
  companyWebsite: "",
};
const SignUpRecruiter = () => {
  const { redirectReg } = useSelector((state) => state?.Auth);
  const [user, setUser] = useState(intialvalue);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [googleAuthDetails, setgoogleAuthDetails] = useState({});


  // Google Login Hook
    const googleLogin = useGoogleLogin({
      onSuccess: tokenResponse => {
        const decoded = jwtDecode(tokenResponse?.credential);
        responseGoogle(decoded);
      },
      onError: () => console.log('Login Failed'),
    });

  // Form Validation
  const validation = () => {
    let error = {};
    if (!user.name) {
      error.name = "@Name is Required";
    }
    if (!user.email) {
      error.email = "@Email is Required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        user.email
      )
    ) {
      error.email = "@Enter a Valid Email";
    }
    if (!user.password) {
      error.password = "@Password is Required";
    }
    if (!user.mobile) {
      error.mobile = "@Mobile is Required";
    }
    if (!user.designation) {
      error.designation = "@Designation is Required";
    }

    return error;
  };

  // onChange Validation
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });

    if (name === "name") {
      if (value.length === 0) {
        setError({ ...error, name: "Name is Required" });
        setUser({ ...user, name: "" });
      } else {
        setError({ ...error, name: "" });
        setUser({ ...user, name: value });
      }
    }
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Email is required" });
        setUser({ ...user, email: "" });
      } else {
        setError({ ...error, email: "" });
        setUser({ ...user, email: value });
      }
    }
    if (name === "mobile") {
      if (value.length === 0) {
        setError({ ...error, mobile: "Mobile is Required" });
        setUser({ ...user, mobile: "" });
      } else {
        setError({ ...error, mobile: "" });
        setUser({ ...user, mobile: value });
      }
    }
    if (name === "password") {
      if (value.length === 0) {
        setError({ ...error, password: "Password is Required" });
        setUser({ ...user, password: "" });
      } else {
        setError({ ...error, password: "" });
        setUser({ ...user, password: value });
      }
    }
    if (name === "designation") {
      if (value.length === 0) {
        setError({ ...error, designation: "Designation is Required" });
        setUser({ ...user, designation: "" });
      } else {
        setError({ ...error, designation: "" });
        setUser({ ...user, designation: value });
      }
    }
  };
  // Submit Button
  const SubmitInfo = async (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(ErrorList);
    let data = {
      name: user.name,
      email: user.email,
      password: user.password,
      mobile: user.mobile,
      role_id: "Employer",
      designation: user.designation,
      company: {
        name: user.companyName,
        website: user.companyWebsite,
      },
    };
    dispatch(registerUser(user));
  };


  // Google Authentication
  const responseGoogle = async (response) => {
    if (response) {
      // setShowModal(true);
      let data = {
        name: response?.name,
        email: response.email,
        google_id: response?.sub,
        role_id: "Employer",
      };
      dispatch(googleRegisterRequest(data));
    }
  };

  // Google Authentication Register
  const googlauthHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
    dispatch(googleRegisterRequest(googleAuthDetails));
  };

  useEffect(() => {
    // Redirect
    const redirectUser = () => {
      let name = localStorage.getItem("name");
      let isInLoginPage =
        window.location.pathname.toLowerCase() === "/signup-recruiter";
      if (name !== null && name !== undefined && name !== "") {
        isInLoginPage && navigate("/");
      }
    };
    document.title = "SignUp | CAJobPortal";

    redirectUser();
  }, [redirectReg]);

  return (
    <section className="wrapper">
      <div className="container pt-5">
        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
          <form className="rounded bg-white shadow py-4 px-4">
            <h3 className="text-dark fw-bolder fs-4 mb-2">Create an Account</h3>
            <div className="fw-normal text-muted ">
              Already have an account?
              <Link
                to={"/signin"}
                className="text-primary fw-weight text-decoration-none"
              >
                {" "}
                Sign in Here{" "}
              </Link>
            </div>
            <span>Or</span>
            <div className="fw-normal text-muted mb-3">
              Register as
              <Link
                to={"/signup"}
                className="text-primary fw-weight text-decoration-none"
              >
                {" "}
                Job Seeker{" "}
              </Link>
            </div>
            <div className="form-floating mb-3">
              <input
                name="name"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Your Name"
              />
              <label htmlFor="floatingInput">Name</label>
              <span style={{ color: "red" }}> {error.name} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="email"
                onChange={(e) => postUserData(e)}
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="Your Email"
              />
              <label htmlFor="floatingInput">Email</label>
              <span style={{ color: "red" }}> {error.email} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="mobile"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Mobile Number</label>
              <span style={{ color: "red" }}> {error.mobile} </span>
            </div>
            <div className="form-floating pwd-container mb-3">
              <input
                name="password"
                onChange={(e) => postUserData(e)}
                type={isRevealPwd ? "text" : "password"}
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
              />
               <img
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  alt="passwd_img"
                  src={isRevealPwd ? hidePwdImg : showPwdImg}
                  onClick={() => setIsRevealPwd(prevState => !prevState)}
                 />
              <label htmlFor="floatingPassword">Password</label>
              <span style={{ color: "red" }}> {error.password} </span>
            </div>

            <div className="form-floating mb-3">
              <input
                name="designation"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Designation</label>
              <span style={{ color: "red" }}> {error.designation} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="company_name"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Company Name</label>
              <span style={{ color: "red" }}> {error.companyName} </span>
            </div>
            <div className="form-floating mb-3">
              <input
                name="company_website"
                onChange={(e) => postUserData(e)}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Company Website</label>
              <span style={{ color: "red" }}> {error.companyWebsite} </span>
            </div>
            <button
              onClick={SubmitInfo}
              type="submit"
              className="btn btn-primary submit_btn_signInRecruiter w-100 my-2"
            >
              Sign Up
            </button>
            <div className="text-center text-muted text-uppercase mb-3">or</div>
            {/* Custom Google Sign-In Button */}
            <button 
              onClick={googleLogin}
              className="btn btn-outline-primary w-100 d-flex align-items-center justify-content-center"
              style={{
                height: '45px',
                borderColor: '#ddd',
                color: '#444',
                fontSize: '15px',
                gap: '10px'
              }}
            >
              <img 
                src={ Icongoogle }
                alt="Google logo" 
                style={{ width: '20px' }}
              />
              Sign Up with Google
            </button>       
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUpRecruiter;
